"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import utils from 'styles/globals/utils.module.scss';
import CardLinks from "components/display/CardLinks";
import CardMedia from "components/display/CardMedia";
import CardBody from "components/display/CardBody";
import CardTitle from "components/display/CardTitle";
//import Link from 'next/link';
import SmartLink from 'components/navigation/SmartLink';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Style from './CardContent.module.scss'


const CardCalloutLink = dynamic(() => import('./CardCalloutLink'))


function CardContent({ card, imageLink }){
    const [ cardLinks, setCardLinks ] = useState(null)
    const [isTextAbsent, setIsTextAbsent] = useState(false);

    useEffect(() => {
        setCardLinks(
            <>
                {card?.cardLinks.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            {card?.cardLinkArea ?
                                <>
                                    {item?.ctaTitle?.text ?? item?.ctaTitle}
                                </>
                            :
                                <SmartLink
                                    href={item?.ctaLink ?? "#"}
                                    className={clsx(utils.cta_1, utils.m_0, utils.text_capitalize, utils[`justify_content_${card?.align}`])}
                                >
                                    {item?.ctaTitle?.text ?? item?.ctaTitle}
                                </SmartLink>
                            }
                        </React.Fragment>
                    );
                })}
            </>
        )

    }, [ card?.cardLinks ])

    useEffect(() => {
        if (!card?.text && !card?.cardTitle && !card?.cardLinks?.length) {
            setIsTextAbsent(true);
        } else{
            setIsTextAbsent(false);
        }
    }, [card]);



    return(
        <>
            {(card?.calloutLink && card?.calloutLinkTitle) && (
               <CardCalloutLink {...{ card }} />
            )}
            

            {card?.image[0] && (
                <CardMedia 
                    className={Style.smallImage}
                    image={card.image[0]} 
                    type="image" 
                    imageLink={imageLink}
                />
            )}
               

            {card?.cardTitle && (
                <CardTitle align="left">
                    <h6 className={clsx(utils.fs_4, utils[`text_${card.align}`], Style.titlePadding)}>
                        {card.cardTitle}
                    </h6>
                </CardTitle>
            )}
            

            {card?.text && (
                <CardBody className={clsx(Style.cardBodyPadding)} align={card?.align ?? "left"}>
                    <span dangerouslySetInnerHTML={{__html: card.text}}/>
                </CardBody>
            )}
            
            
            {Array.isArray(card?.cardLinks) && card?.cardLinks.length ? 
                <CardLinks className={clsx(utils.flex_column, utils.lh_lg)}>
                    {cardLinks}
                </CardLinks>
            :null}
            
        </>
    )
}


export default CardContent;