"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import Style from './CollectionRow.module.scss'
import utils from "styles/globals/utils.module.scss";
import PropTypes from "prop-types";
import clsx from "clsx";
import React, { useMemo, useState } from 'react';
import CollectionPod from './CollectionPod';
import PodSlider from 'components/product/PodSlider';
import useCurrentSlide from 'components/product/PodSlider/hooks/useCurrentSlide';

function CollectionRow({ 
    backgroundColor, 
    element = "h2", 
    heading, 
    headingType, 
    textColor, 
    collection = [],
    size = 4,
    tradeStatus,
    customer_group_id
}){

    const [ lastChild, setLastChild ] = useState(false)
    const currentSlide = useCurrentSlide()
    
    const settings = {
        initial: 0,
        loop: false,
        animationEnded(s) {
            currentSlide.setCurrent(s.track.details.rel)
        },
        breakpoints: {
            '(min-width: 1280px)': {
                slides: {
                    perView: 4,
                }
            },
            '(min-width: 1024px)': {
                slides: {
                    perView: 2.5,
                }
            },
            '(max-width: 850px)': {
                slides: {
                    perView: 2.5,
                }
            },
            '(max-width: 480px)': {
                slides: {
                    perView: 1.5,
                }
            }
        }
    }

    const CollectionPods = useMemo(() => {
        if( !currentSlide?.loaded.length && !collection.length ) return null;

        return collection.map((item, index) => {
            
            // if currentSlide.loaded length is 1 then make index result 0 through 4 true
            // if currentSlide.loaded length is 2 then make index 5 true
            // and so on for each index
            const isLoaded = index < (currentSlide.loaded.length + settings.breakpoints['(min-width: 1280px)'].slides.perView);

            return(
                <CollectionPod 
                    {...item} 
                    key={item.id}
                    position={index + 1}
                    totalChildren={collection.length} 
                    onLastChild={(last) => setLastChild(last)}
                    isLoaded={isLoaded}
                />
            )
        })

    }, [ 
        collection, 
        currentSlide.loaded 
    ])



    if (customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    }


    return(
        <section className={clsx(
                Style.block, 
                utils[`bg_${backgroundColor?.class}`], 
                utils[`text_${textColor?.class}`]
            )} 
        >  
            {CollectionPods && (
                <PodSlider
                    showTitle={!!heading}
                    position="start"
                    title={heading}
                    size={size}
                    mobileSlidesToScroll={1}
                    settings={settings}
                    {...{ 
                        headingType, 
                        element, 
                        lastChild,
                        color: textColor?.class, 
                        className: Style.heading 
                    }}
                >
                    {CollectionPods}
                </PodSlider>
            )}
        </section>
    )
    
}


CollectionRow.propTypes = {
    backgroundColor: PropTypes.object, 
    element: PropTypes.string, 
    heading: PropTypes.string, 
    headingType: PropTypes.string, 
    textColor: PropTypes.object, 
    collection: PropTypes.array
}


export default CollectionRow;